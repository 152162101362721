/***
Input icons
***/

.input-icon {
    position: relative;
    left: 0;


    > .form-control {
        padding-left: 33px;

        .input-group & {
             @include border-radius($general-border-radius 0 0 $general-border-radius);
        }
    }

    > i {
        color: #ccc;
        display: block;
        position: absolute;
        margin: 11px 2px 4px 10px;
        z-index: 3;
        width: 16px;
        font-size: 16px;
        text-align: center;

        .modal & {
            z-index: $zindex-modal + 5;
        }

        .has-success & {
            color: $brand-success;
        }

        .has-warning & {
            color: $brand-warning; 
        }

        .has-info & {
            color: $brand-info;
        }

        .has-error & {
            color: $brand-danger;
        }
    }

    &.right {
        left: auto;
        right: 0;
        
        > .form-control {
            padding-right: 33px;
            padding-left: 12px;

            .input-group & {
                @include border-radius(0 $general-border-radius $general-border-radius 0); 
            }
        }

        > i {
            right: 8px;
            float: right;
        } 
    }

    &.input-icon-lg {
        > i {
            margin-top: 16px;  
        }
    }

    &.input-icon-sm {
        > i {
            margin-top: 8px;      
            font-size: 13px;    
        } 
    }
}