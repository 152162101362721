.mt-comments .mt-comment {
  padding: 10px;
  margin: 0 0 10px 0;
  .mt-comment-img {
    width: 40px;
    float: left;
    > img {
      border-radius: 50% !important;
      width: 30px;
    }
  }
  .mt-comment-body {
    position: relative;

    &.inner{
      padding-left: 40px;
    }

    .mt-comment-container{
      width: calc(100% - 100px);
      float: left;
      margin-top: 4px;
    }

    .mt-comment-info {
      &:before {
        content: " ";
        display: table;
      }
      &:after {
        content: " ";
        display: table;
        clear: both;
      }
      .mt-comment-author {
        display: inline-block;
        float: left;
        margin: 0px 0px 10px 0;
        color: #060606;
        font-weight: 600;
      }

      .mt-comment-label {
        display: inline-block;
        float: left;
        margin: 0 0 0 15px;
      }

      .mt-comment-date {
        display: inline-block;
        float: right;
        margin: 0px;
        color: #BABABA;
      }
    }
    .mt-comment-text {
      color: #a6a6a6;
    }
    .mt-comment-details {
      margin: 10px 0px 0px 0;
      .mt-comment-status {
        text-transform: uppercase;
        float: left;
        &.mt-comment-status-pending {
          color: #B8C0F5;
        }
        &.mt-comment-status-approved {
          color: #6BD873;
        }
        &.mt-comment-status-rejected {
          color: red;
        }
      }
    }
    .mt-comment-actions {
      list-style: none;
      margin: 0;
      padding: 0;
      float: right;
      > li {
        float: left;
        padding: 0 5px;
        margin: 0;
      }
    }
  }
  &:hover {
    background: #f9f9f9;
    .mt-comment-actions {
      display: inline-block;
    }
  }
}