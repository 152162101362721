$table-cell-padding : 14px;

@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../../bower_components/font-awesome/scss/font-awesome";
@import "../../../bower_components/simple-line-icons/scss/simple-line-icons";
@import "../../../bower_components/toastr/toastr.scss";
@import "../../../bower_components/bootstrap-daterangepicker/daterangepicker.scss";

@import "global/components-square";
@import "layouts/layout/layout";
@import "layouts/layout/themes/falevi";

@import "plugins/jquery.uniform";
@import "plugins/datatables";
@import "plugins/daterangepicker";

@import "pages/fortune-detail";
@import "pages/user-detail";
@import "pages/login";
