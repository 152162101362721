@mixin opacity($opacity) {
	opacity: $opacity;
	filter: unquote("alpha(opacity=#{round($opacity * 100)})");
	-moz-opacity: $opacity;
}

@mixin hideYetClickable() {
	@include opacity(0);
	border: none;
	background: none;
}

@mixin inline-block() {
	display: -moz-inline-box;
	display: inline-block;
	*display: inline;
	zoom: 1;
}

@mixin ellipsis() {
	text-overflow: ellipsis;
	display: block;
	overflow: hidden;
	white-space: nowrap;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin box-shadow($def) {
	-webkit-box-shadow: $def;
	-moz-box-shadow: $def;
	box-shadow: $def;
}

@mixin retina() {
	@media only screen {
		@media (min-resolution: 124dpi), (-webkit-min-device-pixel-ratio: 1.3), (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 4/3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
			@include use-backgrounds(url($sprite-retina), $sprite-size, url($input-background-retina), url($input-background-focus-retina), $input-background-size);
		}
	}
}

@mixin use-backgrounds($sprite, $sprite-size, $input, $input-focus, $input-size) {
	div#{$class-wrapper} {
		&#{$class-select},
		&#{$class-select} span,
		&#{$class-checkbox} span,
		&#{$class-radio} span,
		&#{$class-upload},
		&#{$class-upload} span#{$class-action},
		&#{$class-button},
		&#{$class-button} span {
			background-image: $sprite;

			@if $sprite-size > 0 {
				background-size: $sprite-size;
			}
		}
	}

	#{$class-wrapper-element}#{$class-wrapper} input#{$class-input},
    #{$class-wrapper-element}#{$class-wrapper} select#{$class-multiselect},
    #{$class-wrapper-element}#{$class-wrapper} textarea#{$class-textarea} {
		background-image: $input;

		@if $sprite-size > 0 {
			background-size: $input-size;
		}

		@include whenActive {
			background-image: $input-focus;
		}
	}
}

@mixin whenActive {
	&#{$class-active} {
		@content
	}
}

@mixin whenHover {
	&#{$class-hover}, &#{$class-focus} {
		@content
	}
}

@mixin whenDisabled {
	&#{$class-disabled}, &#{$class-disabled}#{$class-active} {
		@content
	}
}

@mixin whenChecked {
	&#{$class-checked} {
		@content
	}
}

$jquery-uniform-img-path: "../images" !default;
$sprite: "#{$jquery-uniform-img-path}/sprite.png" !default;
$sprite-retina: "#{$jquery-uniform-img-path}/sprite-retina.png" !default;
$sprite-size: 493px !default;
$button-height: 30px !default;
$button-margin-left: 13px !default;
$button-padding: 0 !default;
$button-span-height: $button-height !default;
$checkbox-height: 19px !default;
$checkbox-width: 19px !default;
$input-padding: 3px !default;
$input-background: "#{$jquery-uniform-img-path}/bg-input.png" !default;
$input-background-retina: "#{$jquery-uniform-img-path}/bg-input-retina.png" !default;
$input-background-focus: "#{$jquery-uniform-img-path}/bg-input-focus.png" !default;
$input-background-focus-retina: "#{$jquery-uniform-img-path}/bg-input-focus-retina.png" !default;
$input-background-size: 1px !default;
$radio-height: 18px !default;
$radio-width: 18px !default;
$select-fixed-width: 190px !default;
$select-height: 26px !default;
$select-margin-left: 10px !default;
$select-margin-right: 25px !default;
$select-select-height: 22px !default;
$select-select-top: 2px !default;
$upload-action-width: 85px !default;
$upload-filename-margin-top: 2px !default;
$upload-filename-margin-bottom: 2px !default;
$upload-filename-margin-left: 2px !default;
$upload-filename-width: 82px !default;
$upload-filename-padding: 0 10px !default;
$upload-height: 28px !default;
$upload-width: 190px !default;

$checkbox-voffset: (-10 * $select-height);
$radio-voffset: ($checkbox-voffset - $checkbox-height);
$upload-voffset: ($radio-voffset - $radio-height);
$button-voffset: ($upload-voffset - (8 * $upload-height));

$class-action: ".action" !default;
$class-active: ".active" !default;
$class-button: ".button" !default;
$class-checkbox: ".checker" !default;
$class-checked: ".checked" !default;
$class-disabled: ".disabled" !default;
$class-input: ".uniform-input" !default;
$class-filename: ".filename" !default;
$class-focus: ".focus" !default;
$class-hover: ".hover" !default;
$class-multiselect: ".uniform-multiselect" !default;
$class-radio: ".radio" !default;
$class-select: ".selector" !default;
$class-upload: ".uploader" !default;
$class-textarea: ".uniform" !default;
$class-wrapper: "" !default;

$class-wrapper-element: "";
@if $class-wrapper != "" {
	$class-wrapper-element: "span"
}

/* General settings */

div#{$class-wrapper} {
	&#{$class-select},
	&#{$class-select} span,
	&#{$class-checkbox} span,
	&#{$class-radio} span,
	&#{$class-upload},
	&#{$class-upload} span#{$class-action},
	&#{$class-button},
	&#{$class-button} span {
		background-image: url($sprite);
		background-repeat: no-repeat;
		-webkit-font-smoothing: antialiased;
	}

	&#{$class-select},
	&#{$class-checkbox},
	&#{$class-button},
	&#{$class-radio},
	&#{$class-upload} {
		@include inline-block();
		vertical-align: middle;

		/* Keeping this as :focus to remove browser styles */
		&:focus {
			outline: 0;
		}
	}

	&#{$class-select},
	&#{$class-radio},
	&#{$class-checkbox},
	&#{$class-upload},
	&#{$class-button} {
		&, & * {
			margin: 0;
			padding: 0;
		}
	}
}

.highContrastDetect {
	background: url($input-background) repeat-x 0 0;
	width: 0px;
	height: 0px;
}

/* Input & Textarea */

#{$class-wrapper-element}#{$class-wrapper} input#{$class-input},
#{$class-wrapper-element}#{$class-wrapper} select#{$class-multiselect},
#{$class-wrapper-element}#{$class-wrapper} textarea#{$class-textarea} {
	padding: $input-padding;
	background: url($input-background) repeat-x 0 0;
	outline: 0;

	@include whenActive {
		background: url($input-background-focus) repeat-x 0 0;
	}
}

/* Remove default webkit and possible mozilla .search styles.
 * Keeping this as :active to remove browser styles */
div#{$class-wrapper}#{$class-checkbox} input,
input[type="search"],
input[type="search"]:active  {
	-moz-appearance: none;
	-webkit-appearance: none;
}

/* Select */

div#{$class-wrapper}#{$class-select} {
	background-position: 0 (-5 * $select-height);
	line-height: $select-height;
	height: $select-height;
	padding: 0 0 0 $select-margin-left;
	position: relative;
	overflow: hidden;

	span {
		@include ellipsis();
		background-position: right 0;
		height: $select-height;
		line-height: $select-height;
		padding-right: $select-margin-right;
		cursor: pointer;
		width: 100%;
		display: block;
	}

	&.fixedWidth{
		width: $select-fixed-width;

		span {
			width: ($select-fixed-width - $select-margin-left - $select-margin-right);
		}
	}

	select {
		@include hideYetClickable();
		position: absolute;
		height: $select-select-height;
		top: $select-select-top;
		left: 0px;
		width: 100%;
	}

	@include whenActive {
		background-position: 0 (-6 * $select-height);

		span {
			background-position: right (-1 * $select-height);
		}
	}

	@include whenHover {
		background-position: 0 (-7 * $select-height);

		span {
			background-position: right (-2 * $select-height);
		}

		@include whenActive {
			background-position: 0 (-8 * $select-height);

			span {
				background-position: right (-3 * $select-height);
			}
		}
	}

	@include whenDisabled {
		background-position: 0 (-9 * $select-height);

		span {
			background-position: right (-4 * $select-height);
		}
	}
}


/* Checkbox */

div#{$class-wrapper}#{$class-checkbox} {
	position: relative;

	&, span, input {
		width: $checkbox-width;
		height: $checkbox-height;
	}

	span {
		@include inline-block();
		text-align: center;
		background-position: 0 $checkbox-voffset;

		@include whenChecked {
			background-position: (-4 * $checkbox-width) $checkbox-voffset;
		}
	}

	input {
		@include hideYetClickable();
		@include inline-block();
	}

	@include whenActive {
		span {
			background-position: (-1 * $checkbox-width) $checkbox-voffset;
		
			@include whenChecked {
				background-position: (-5 * $checkbox-width) $checkbox-voffset;
			}
		}
	}

	@include whenHover {
		span {
			background-position: (-2 * $checkbox-width) $checkbox-voffset;

			@include whenChecked {
				background-position: (-6 * $checkbox-width) $checkbox-voffset;
			}
		}

		@include whenActive {
			span {
				background-position: (-3 * $checkbox-width) $checkbox-voffset;

				@include whenChecked {
					background-position: (-7 * $checkbox-width) $checkbox-voffset;
				}
			}
		}
	}

	@include whenDisabled {
		span {
    		background-position: (-8 * $checkbox-width) $checkbox-voffset;

			@include whenChecked {
				background-position: (-9 * $checkbox-width) $checkbox-voffset;
			}
		}
	}
}

/* Radio */

div#{$class-wrapper}#{$class-radio} {
	position: relative;

	&, span, input {
		width: $radio-width;
		height: $radio-height;
	}

	span {
		@include inline-block();
		text-align: center;
		background-position: 0 $radio-voffset;

		@include whenChecked {
			background-position: (-4 * $radio-width) $radio-voffset;
		}
	}

	input {
		@include hideYetClickable();
		@include inline-block();
		text-align: center;
	}

	@include whenActive {
		span {
			background-position: (-1 * $radio-width) $radio-voffset;

			@include whenChecked {
				background-position: (-5 * $radio-width) $radio-voffset;
			}
		}
	}

	@include whenHover {
		span {
			background-position: (-2 * $radio-width) $radio-voffset;

			@include whenChecked {
				background-position: (-6 * $radio-width) $radio-voffset;
			}
		}

		@include whenActive {
			span {
				background-position: (-3 * $radio-width) $radio-voffset;

				@include whenChecked {
					background-position: (-7 * $radio-width) $radio-voffset;
				}
			}
		}
	}

	@include whenDisabled {
		span {
			background-position: (-8 * $radio-width) $radio-voffset;

			@include whenChecked {
				background-position: (-9 * $radio-width) $radio-voffset;
			}
		}
	}
}

/* Uploader */

div#{$class-wrapper}#{$class-upload} {
	background-position: 0 $upload-voffset;
	height: $upload-height;
	width: $upload-width;
	cursor: pointer;
	position: relative;
	overflow: hidden;

	span#{$class-action} {
		background-position: right ($upload-voffset + (-4 * $upload-height));
		height: $upload-height;
		line-height: $upload-height;
		width: $upload-action-width;
		text-align: center;
		float: left;
		display: inline;
		overflow: hidden;
		cursor: pointer;
	}

	span#{$class-filename} {
		@include ellipsis();
		float: left;
		cursor: default;
		height: ($upload-height - $upload-filename-margin-top - $upload-filename-margin-bottom);
		margin: $upload-filename-margin-top 0 $upload-filename-margin-bottom $upload-filename-margin-left;
		line-height: ($upload-height - $upload-filename-margin-top - $upload-filename-margin-bottom);
		width: $upload-filename-width;
		padding: $upload-filename-padding;
	}

	input {
		@include hideYetClickable();
		position: absolute;
		top: 0;
		right: 0;
		float: right;
		cursor: default;
		width: 100%;
		height: 100%;
	}

	@include whenActive {
		span#{$class-action} {
			background-position: right ($upload-voffset + (-6 * $upload-height));
		}
	}

	@include whenHover {
		background-position: 0 ($upload-voffset + (-2 * $upload-height));

		span#{$class-action} {
			background-position: right ($upload-voffset + (-5 * $upload-height));
		}

		@include whenActive {
			span#{$class-action} {
				background-position: right ($upload-voffset + (-7 * $upload-height));
			}
		}
	}

	@include whenDisabled {
		background-position: 0 ($upload-voffset + (-1 * $upload-height));
		
		span#{$class-action} {
			background-position: right ($upload-voffset + (-3 * $upload-height));
		}
	}
}

/* Buttons */

div#{$class-wrapper}#{$class-button} {
	background-position: 0 ($button-voffset + (-4 * $button-height));
	height: $button-height;
	cursor: pointer;
	position: relative;

	/* Keep buttons barely visible so they can get focus */
	a, button, input {
		@include opacity(0.01);
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: absolute;
	}

	span {
		@include inline-block();
		line-height: $button-span-height;
		text-align: center;
		background-position: right $button-voffset;
		height: $button-span-height;
		margin-left: $button-margin-left;
		padding: $button-padding;
	}

	@include whenActive {
		background-position: 0 ($button-voffset + (-5 * $button-height));

		span {
			background-position: right ($button-voffset + (-1 * $button-height));
			cursor: default;
		}
	}

	@include whenHover {
		background-position: 0 ($button-voffset + (-6 * $button-height));

		span {
			background-position: right ($button-voffset + (-2 * $button-height)); 
		}
	}

	@include whenDisabled {
		background-position: 0 ($button-voffset + (-7 * $button-height));

		span {
			background-position: right ($button-voffset + (-3 * $button-height));
			cursor: default;
		}
	}
}
